const _nav = [
  {
    'class': 'wbudowie',
    'style': '1',
    'size': '8',
    'primaryMenu': [
      {
        'id': '101',
        'to': 'regulamin',
        'name': 'regulations',
        'text': 'Regulamin',
        'link': 'false',
        'follow': 'false'
      },
      {
        'id': '102',
        'name': 'privatepolicy',
        'to': 'polityka-prywatnosci',
        'text': 'Polityka prywatności',
        'link': 'false',
        'follow': 'false'
      }
    ],
    'secondaryMenu': [
      {
        'id': '100',
        'to': '',
        'name': 'Wbudowie',
        'link': '#',
        'follow': 'false'
      },
      {
        'id': '101',
        'to': '',
        'name': 'Artykuły',
        'link': '#',
        'follow': 'false'
      },
      {
        'id': '102',
        'to': '',
        'name': 'Projekty domów',
        'link': '#',
        'follow': 'false'
      },
      {
        'id': '103',
        'to': '',
        'name': 'Firmy',
        'link': '#',
        'follow': 'false'
      }
    ]
  }
]

export default {
  getNav (cb) {
    setTimeout(() => cb(_nav), 100)
  }
}
