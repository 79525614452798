const _header = [
  {
    'logo': '//wbudowie.pl/assets/images/svg/logo-wbudowie.svg',
    'logo_alt': 'wiedza wbudowie',
    'class': 'wbudowie',
    'style': '1',
    'size': '4'
  }
]

export default {
  getHeader (cb) {
    setTimeout(() => cb(_header), 100)
  }
}
