/**
 * Mocking client-server processing
 */
const _articles = [
  {
    'id': 1,
    'title': 'Kominiarz potrzebny od zaraz. Kłopoty z przewodami kominowymi.',
    'meta': 'Kominiarz jest konieczny w przypadku pojawienia się dymu czy nieprzyjemnego zapachu w domu.',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/kominiarz-potrzebny-od-zaraz-360x189.jpeg',
        'alt': 'kominiarz',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/kominiarz-potrzebny-od-zaraz-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/kominiarz-potrzebny-od-zaraz.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': '<strong>Kłopoty z przewodami kominowymi</strong> mogą się zacząć w momencie gdy zostaną one źle zaprojektowane lub zostaną zbudowane niezgodnie z przepisami. Materiały z jakich powinny być zbudowane muszą być niepalne i muszą spełniać wymagania określone w Polskiej Normie dotyczącej badań ogniowych małych kominów. ',
    'content': [
      { 'section': '<strong>Kłopoty z przewodami kominowymi</strong> mogą się zacząć w momencie gdy zostaną one <strong>źle zaprojektowane lub zostaną zbudowane niezgodnie z przepisami</strong>. ' },
      { 'section': 'Materiały z jakich powinny być zbudowane muszą być niepalne i muszą spełniać wymagania <strong>określone w Polskiej Normie</strong> dotyczącej badań ogniowych małych kominów. ' },
      { 'section': '<strong>Wyloty przewodów kominowych</strong> powinny być wyprowadzane na wysokość (powinny być wyprowadzone ponad dach), która będzie odpowiednia i nie zakłóci ciągu.' },
      { 'section': 'Rodzaje przewodów kominowych:' },
      { 'section': '<strong>Kominy spalinowe</strong> – odprowadzają spaliny o stosunkowo niskiej temperaturze z pieców olejowych bądź gazowych. Wkład przeważnie jest <strong>ze stali kwasoodpornej lub kamionkowy</strong> ponieważ musi być odporny na działanie kwaśnych skroplin.' },
      { 'section': '<strong>Kominy powietrzno-spalinowe</strong> – przeznaczone są do kotłów z zamkniętą komorą spalania ' },
      { 'section': '<strong>Kominy dymowe</strong> - nadają się do kotłów na paliwo stałe, kominków czy pieców kaflowych ponieważ służą do odprowadzania dymu. Nie wymagają instalowania wewnątrz wkładu' },
      { 'section': 'Kominy uniwersalne- odporne na działanie kwasu i wysoką temperaturę, nadają się do kotłów na paliwo stałe, olej czy gaz' },
      { 'section': '<strong>Kominy wentylacyjne</strong> - odpowiedzialny za odprowadzenie zanieczyszczonego powietrza z domu. ' },
      { 'section': 'Sprawnie działające <strong>przewody kominowe</strong> odpowiedzialne są za prawidłową wentylację i odprowadzanie zanieczyszczonego powietrza z domu. Musimy zwrócić szczególną uwagę w przypadku parujących szyb czy nadmiernej wilgoci w domu, gdyż może ona świadczyć o źle działających przewodach kominowych. <strong>Kominiarz</strong> jest konieczny w przypadku pojawienia się dymu czy nieprzyjemnego zapachu w domu. Przyczyną może okazać się nagromadzona sadza lub zanieczyszczenia zewnętrzne.' }
    ],
    'slug': 'kominiarz-potrzebny-od-zaraz',
    'outerlink': 'https://wbudowie.pl/kominiarz-potrzebny-od-zaraz-klopoty-z-przewodami-kominowymi'
  },
  {
    'id': 2,
    'title': 'Zatkany komin – jak wyczyścić i zapobiegać.',
    'meta': 'Prawidłowo funkcjonujący komin musi być przede wszystkim szczelny i musi mieć odpowiedni ciąg.',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/zatkany-komin-360x189.jpeg',
        'alt': 'zatkany komin',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/zatkany-komin-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/zatkany-komin.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': 'Prawidłowo funkcjonujący komin musi być przede wszystkim szczelny i musi mieć odpowiedni ciąg. Co to oznacza w praktyce? Nasz komin musi sprawnie odprowadzać spaliny, a także zapewnić nam odpowiednią wymianę powietrza w pomieszczeniach.',
    'content': [
      { 'section': '<strong>Prawidłowo funkcjonujący komin</strong> musi być przede wszystkim szczelny i musi mieć odpowiedni ciąg. Co to oznacza w praktyce? <strong>Nasz komin musi sprawnie odprowadzać spaliny</strong>, a także zapewnić nam odpowiednią wymianę powietrza w pomieszczeniach.' },
      { 'section': 'Częstotliwość kontroli kominiarskich określają przepisy, jest to w przypadku <strong>domu ogrzewanego paliwem stałym</strong> raz na kwartał, a w przypadku gazu i oleju raz na pół roku. Jest to konieczne, gdyż zaniedbanie tego obowiązku może skończyć się tragicznie.' },
      { 'section': '<strong>Jednym z powodów zatykania się komina</strong> może być jego nadmierne wychładzanie – podczas utrzymywania wysokiej temperatury komin wolniej zachodzi sadzą co ogranicza jej zapalenie. <strong>Kolejna z przyczyn to niewłaściwe paliwo</strong>. Nigdy <strong>nie wolno do pieca wrzucać śmieci czy plastikowych butelek</strong>. Palenie źle składowanym drewnem (mokre, żywiczne) skutecznie prowadzi do zatkania komina.' },
      { 'section': 'Do czyszczenia komina nie koniecznie musimy wzywać kominiarza, możemy zrobić to sami, ale do tego będą nam potrzebne profesjonalne przyrządy takie jak :' },
      { 'section': 'Szczotka kominiarska – musi być dopasowana do przekroju komina ' },
      { 'section': 'Kilkukilogramowy obciążnik dobrze przymocowany do szczotki' },
      { 'section': 'Kilkanaście metrów stalowej linki co najmniej 3mm grubej.' },
      { 'section': '<strong>Szczotkowanie komina</strong> powinno obyć się kilka razy po całej długości. Następnie z kotłowni trzeba ostrożnie <strong>wybrać zgromadzoną sadzę</strong>. Całą czynność należy powtórzyć i oczywiście sprawdzić drożność komina za pomocą latarki i lusterka.' }
    ],
    'slug': 'zatkany-komin-jak-wyczyscic-i-zapobiegac',
    'outerlink': 'https://wbudowie.pl/zatkany-komin-jak-wyczyscic-i-zapobiegac'
  },
  {
    'id': 3,
    'title': 'Sezon grzewczy – przegląd instalacji kominowej.',
    'meta': 'Przygotowania do sezonu grzewczego tak naprawdę powinniśmy zacząć od sprawdzenia instalacji kominowej.',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/sezon-grzewczy-360x189.jpeg',
        'alt': 'przegląd instalacji kominowej',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/sezon-grzewczy-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/sezon-grzewczy.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': 'Przygotowania do sezonu grzewczego tak naprawdę powinniśmy zacząć od sprawdzenia instalacji kominowej. Sprawnie działająca zapewni nam spokój i zdrowie przez okres zimowy. Bez względu na to czy palimy w domu węglem, gazem czy innym paliwem, naszym obowiązkiem jest sprawdzenie instalacji kominowej.',
    'content': [
      { 'section': '<strong>Przygotowania do sezonu grzewczego</strong> tak naprawdę powinniśmy zacząć <strong>od sprawdzenia instalacji kominowej</strong>. Sprawnie działająca zapewni nam spokój i zdrowie przez okres zimowy. Bez względu na to czy <strong>palimy w domu węglem, gazem czy innym paliwem</strong>, naszym obowiązkiem jest <strong>sprawdzenie instalacji kominowej</strong>.' },
      { 'section': 'Kontrola, podczas której sprawdzony powinien być <strong>stan techniczny instalacji</strong> jak i <strong>przewodów kominowych</strong>, powinna odbyć się co najmniej raz w roku. Pamiętajmy, że jeżeli nie przeprowadzimy takiej kontroli, bądź nie usuniemy nieprawidłowości, które zostały wskazane podczas kontroli, <strong>możemy zostać ukarani mandatem</strong>.' },
      { 'section': 'Przeglądu instalacji kominowej powinny dokonywać osoby posiadające kwalifikacje mistrza w rzemiośle kominiarskim. Kontrola taka może być również przeprowadzona z urzędu.' },
      { 'section': '<strong>Po każdej kontroli technicznej powinniśmy otrzymać dokument świadczący o wykonanej czynności.</strong>' },
      { 'section': '<strong>Kominiarz przynosi szczęście</strong> – jakie to prawdziwe., bo czy nie szczęściem jest zdrowie i bezpieczeństwo najbliższych? Nie warto zatem unikać ani <strong>oszczędzać na kontroli wykonanej przez wykwalifikowaną osobę</strong>, bo dzięki sprawnie działającej instalacji w domu <strong>zachodzi prawidłowa cyrkulacja powietrza</strong>, a tym samym eliminujemy ze swojego otoczenia szkodliwe związki.' }
    ],
    'slug': 'sezon-grzewczy-przeglad-instalacji-kominowej',
    'outerlink': 'https://wbudowie.pl/sezon-grzewczy-przeglad-instalacji-kominowej'
  },
  {
    'id': 4,
    'title': 'Kominek w bloku – co na to prawo budowlane.',
    'meta': 'Kominki to piękna ozdoba każdego pokoju i większość z nas marzy aby móc zimowym wieczorem zasiąść przy domowym ognisku.',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/kominek-w-bloku-360x189.jpeg',
        'alt': 'kominek w bloku',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/kominek-w-bloku-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/kominek-w-bloku.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': 'Kominki to piękna ozdoba każdego pokoju i większość z nas marzy aby móc zimowym wieczorem zasiąść przy domowym ognisku. Ale czy każdy może sobie na nie pozwolić?',
    'content': [
      { 'section': '<strong>Kominki to piękna ozdoba każdego pokoju</strong> i większość z nas marzy aby móc zimowym wieczorem zasiąść przy domowym ognisku. Ale czy każdy może sobie na nie pozwolić?' },
      { 'section': 'Jeżeli jesteśmy posiadaczem <strong>mieszkania w bloku czy kamienicy</strong> musimy liczyć się z pewnymi ograniczeniami. <strong>Montaż upragnionego kominka w bloku jednorodzinny jest możliwy</strong>, ale pod warunkiem spełnienia szeregu warunków.' },
      { 'section': 'Konieczna będzie <strong>zgoda wspólnoty mieszkaniowej</strong> co czasami jest kluczową przeszkodą do spełnienia marzenia o własnym kominku, ale pamiętajmy, że <strong>bezpieczeństwo lokatorów jak i nasze jest na pierwszym miejscu</strong>. Przy planowaniu instalacji należy bardzo dokładnie przeanalizować przepisy rozporządzenia Ministra Infrastruktury z 12 kwietnia 2002r.' },
      { 'section': '<strong>Oprócz ograniczeń prawnych</strong>, musimy <strong>spełnić również wymagania techniczne</strong>. Mieszkańcy bloków z więcej niż <strong>czterema kondygnacjami</strong> mogą niestety zapomnieć o możliwości zainstalowania kominka. Należy również sprawdzić czy pomieszczenie w którym planujemy instalacje kominka <strong>ma odpowiednia kubaturę</strong> i własny przewód dymowy. Jeżeli nie potrzebna będzie wspomniana wcześniej zgoda wspólnoty na wybudowanie komina.' },
      { 'section': '<strong>Co z mieszkańcami, którzy nie mogą pozwolić sobie na zamontowanie kominka?</strong> Dla takich osób również jest rozwiązanie. ' },
      { 'section': 'Bardzo dużą popularnością cieszą się <strong>biokominki</strong>, które działają na <strong>biopaliwo</strong>. Są bardzo ekologiczne i <strong>nie wytwarzają dymu ani sadzy</strong> co za tym idzie nie wymagają specjalnego oddymiania. Co również bardzo istotne, można je zamontować w dowolnym miejscu w mieszkaniu. ' },
      { 'section': 'Kominki, które również nie wymagają dodatkowej instalacji to <strong>kominki elektryczne</strong>. Bardzo bezpieczne poprzez to, że nie ma w nich prawdziwego ognia. <strong>Efekt płomienia można uzyskać poprzez podświetlenie pary wodnej.</strong>' },
      { 'section': '' },
      { 'section': '' }
    ],
    'slug': 'kominek-w-bloku-co-na-to-prawo-budowlane',
    'outerlink': 'https://wbudowie.pl/kominek-w-bloku-co-na-to-prawo-budowlane'
  },
  {
    'id': 5,
    'title': 'Jaki kominek wybrać? Rodzaje kominków domowych.',
    'meta': 'Myśląc o instalacji kominka w domu, zaczynamy zastanawiać się jaką będzie pełnił funkcję.',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/jaki-kominek-wybrac-360x189.jpeg',
        'alt': 'jaki kominek wybrać',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/jaki-kominek-wybrac-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/jaki-kominek-wybrac.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': '<strong>Myśląc o instalacji kominka w domu</strong>, zaczynamy zastanawiać się jaką będzie pełnił funkcję. Czy będzie tylko elementem ozdobnym naszego salonu czy może chcemy nim ogrzać cały dom.',
    'content': [
      { 'section': 'Myśląc <strong>o instalacji kominka w domu</strong>, zaczynamy zastanawiać się jaką będzie pełnił funkcję. Czy będzie tylko elementem ozdobnym naszego salonu czy może chcemy nim ogrzać cały dom.' },
      { 'section': 'Kiedyś kominek służył jako palenisko przy którym przygotowane były potrawy dla całej rodziny, dziś to bardziej forma ozdobna, ale dzięki specjalnym instalacjom z powodzeniem możemy ogrzać mi cały dom.' },
      { 'section': 'Musimy pamiętać, iż zgodnie z przepisami kominek nie może być jedynym źródłem ciepła w domu. Budynek musi być wyposażony w główne urządzenie grzewcze takie jak <strong>pompa ciepła</strong>, <strong>kocioł</strong> czy <strong>grzejniki elektryczne</strong>. Kominek może służyć jako zamiennik lub uzupełnienie ogrzewania.  Dostępne rodzaje kominków to:' },
      { 'section': '<strong>Kominek z paleniskiem otwartym</strong> – wykorzystywany jest zazwyczaj jako element wystroju wnętrza. Przez znaczne straty ciepła, które uchodzi wraz z dymem przez komin, efekt grzewczy jest niewielki. Kominek otwarty można wyposażyć w przestawny ekran, który będzie zapobiegał wypadaniu palącego się drewna czy tryskających iskier z paleniska.' },
      { 'section': '<strong>Kominek z zamkniętym paleniskiem</strong> – kominek otwarty można z powodzeniem przerobić na kominek zamknięty, który ma znacznie lepszą efektywność grzewczą. Dzięki kasetom kominkowym, które wstawia się do komory spalania nie musimy rozbierać całej istniejącej już konstrukcji. Kasety kominkowe różnią się od wkładów dodatkowym płaszczem z blachy, który otacza komorę spalania i wylotem ciepłego powietrza nad drzwiczkami wsadowymi.' },
      { 'section': '<strong>Kominek z wkładem kominkowym</strong> , które mogą być przystosowane do pracy w powietrznym systemie ogrzewania (DGP) lub z powodzeniem współpracować z wodną instalacją centralnego ogrzewania (wkład z płaszczem wodnym).' },
      { 'section': '' },
      { 'section': '' }
    ],
    'slug': 'jaki-kominek-wybrac-rodzaje-kominkow-domowych',
    'outerlink': 'https://wbudowie.pl/jaki-kominek-wybrac-rodzaje-kominkow-domowych'
  },
  {
    'id': 6,
    'title': 'Kominek z płaszczem czy bez?',
    'meta': 'Kominki coraz częściej goszczą w naszych domach za względów praktycznych jak i ekonomicznych i dekoracyjnych.',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/kominek-z-plaszczem-czy-bez-360x189.jpeg',
        'alt': 'kominek z płaszczem czy bez',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/kominek-z-plaszczem-czy-bez-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/kominek-z-plaszczem-czy-bez.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': 'Kominki coraz częściej goszczą w naszych domach za względów praktycznych jak i ekonomicznych i dekoracyjnych. Mogą być przydatne przy ogrzewaniu wody jak i całego domu.  Podjęcie decyzji w kwestii wyboru kominka jest bardzo trudne i bardzo ważne. Warto dobrze przemyśleć czego tak naprawdę będziemy oczekiwać od naszego kominka.',
    'content': [
      { 'section': '<strong>Kominki</strong> coraz częściej <strong>goszczą w naszych domach za względów praktycznych jak i ekonomicznych i dekoracyjnych</strong>. Mogą być przydatne przy <strong>ogrzewaniu wody jak i całego domu</strong>.  Podjęcie decyzji w kwestii wyboru kominka jest bardzo trudne i bardzo ważne. Warto dobrze przemyśleć czego tak naprawdę będziemy oczekiwać od naszego kominka.' },
      { 'section': '<strong>Kominek z płaszczem wodnym czy bez?</strong> To pytanie często zadają sobie osoby które stoją przed wyborem kominka.' },
      { 'section': 'Niewątpliwym plusem jest fakt, że <strong>ogrzewanie domu kominkiem z płaszczem wodnym</strong> jest <strong>dużo tańsze niż ogrzewanie kotłem gazowym czy węglem</strong>. Musimy jednak pamiętać, że nie może to być jedyne źródło ciepła, więc i tak koniczna jest instalacja centralnego ogrzewania. W kominku powinniśmy palić odpowiednim drewnem, które jest dobrze wysuszone. Dobre drewno jest droższe, ale bardziej efektywne w użytkowaniu. <strong>Mokre drewno pali się krótko</strong> i wydziela dużą ilość sadzy i smoły.' },
      { 'section': 'W czasie niskich temperatur musimy <strong>dokładać do kominka porcję drewna</strong>, aby nasz dom nie uległ wychłodzeniu, co dla niektórych może być dość męczące.' },
      { 'section': 'Jeżeli decydujemy się na tego typu kominek, już na etapie projektowania budynku powinniśmy uwzględnić wszystkie wymagane elementy aby instalacja działała prawidłowo i bezpiecznie. Bardzo istotne jest wygospodarowanie miejsca na <strong>kanały nawiewne</strong> lub kratki wentylacyjne. Należy zaplanować <strong>doprowadzenie wody do płaszcza wodnego</strong> jak również </strong>odprowadzenie przegrzanej wody do studzienki schładzającej a potem do kanalizacji</strong>.' },
      { 'section': '' },
      { 'section': '' }
    ],
    'slug': 'kominek-z-plaszczem-czy-bez',
    'outerlink': 'https://wbudowie.pl/kominek-z-plaszczem-czy-bez'
  },
  {
    'id': 7,
    'title': 'Kominek tradycyjny kontra gazowy',
    'meta': 'Każdy rodzaj kominka ma swoje wady i zalety. Kominek tradycyjny czy gazowy- przy jednym jak i drugim możemy tak samo się ogrzać.',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/kominek-tradycyjny-kontra-gazowy-360x189.jpeg',
        'alt': 'kominek tradycyjny kontra gazowy',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/kominek-tradycyjny-kontra-gazowy-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/kominek-tradycyjny-kontra-gazowy.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': 'Każdy rodzaj kominka ma swoje wady i zalety. Kominek tradycyjny czy gazowy- przy jednym jak i drugim możemy tak samo się ogrzać. Który więc wybrać ?',
    'content': [
      { 'section': '<strong>Każdy rodzaj kominka ma swoje wady i zalety</strong>. <strong>Kominek tradycyjny czy gazowy</strong> - przy jednym jak i drugim możemy tak samo się ogrzać. Który więc wybrać ?' },
      { 'section': '<strong>Kominek tradycyjny nie jest bezobsługowy</strong>. Stale musimy dokładać drewno, aby podtrzymywać w nim ogień, a po skończonym spalaniu trzeb posprzątać. <strong>A jak to jest z kominkami gazowymi?</strong>' },
      { 'section': 'Przecież nie każdy może zainstalować <strong>kominek na drewno</strong>, a chciałby wieczorami ogrzać się przy płomieniach prawdziwego ognia. <strong>Kominek gazowy</strong> daje taką możliwość, efekt oddawania ciepła z płomienia gazowego jest prawie identyczny z efektem palonego drewna. Istnieje również możliwość zastosowania systemu DGP tak aby kominek ogrzewał cały dom.' },
      { 'section': '<strong>Uruchomienie kominka gazowego jest bardzo wygodne</strong> i nie wymaga wcześniejszych przygotowań. Przed „rozpalenie” nie wymaga czyszczenia tak jak kominek tradycyjny, nie trzeba układać w nim drewna, wystarczy tylko zapalniczka i kominek działa natychmiast po włączeniu. Obsługa ogranicza się jedynie do regulowania wielkości płomienia.' },
      { 'section': '<strong>Wygląd kominka gazowego</strong> niewiele różni się od tego na drewno. Wnętrze zdobi ceramiczna imitacja drewna, węgla i czasami w celu zakrycia palnika, ozdobnych kamieni. Obudowa wkładu jest dokładnie taka sama jak w przypadku kominka tradycyjnego. Przy obudowie elementów gazowych należy pamiętać o łatwym dostępie do nich, aby potem przy konserwacji nie było większych problemów.  ' },
      { 'section': 'Wygląd kominków niczym praktycznie się nie różni, inaczej jest już w kwestii ich budowy. W skład kominka gazowego wchodzą:' },
      { 'section': '<strong>Najczęściej żeliwna obudowa kominka</strong>' },
      { 'section': '<strong>Imitacja paliwa czyli drewna lub kawałków drewna</strong>' },
      { 'section': '<strong>Palnik gazowy</strong>' },
      { 'section': '' }
    ],
    'slug': 'kominek-tradycyjny-kontra-gazowy',
    'outerlink': 'https://wbudowie.pl/kominek-tradycyjny-kontra-gazowy'
  },
  {
    'id': 8,
    'title': 'Komin systemowy wady i zalety – prawda o systemach kominowych.',
    'meta': 'Komin systemowy coraz częściej zaczyna konkurować z tradycyjnym kominem murowanym.',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/komin-systemowy-wady-i-zalety-360x189.jpeg',
        'alt': 'komin systemowy',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/komin-systemowy-wady-i-zalety-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/komin-systemowy-wady-i-zalety.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': 'Komin systemowy coraz częściej zaczyna konkurować z tradycyjnym kominem murowanym. Składa się on z fabrycznie przygotowanych, dopasowanych zestawów rur, kształtek jak i wszelkiego rodzaju akcesoriów pomocniczych.  Wszystkie wykonane są z materiałów odpornych na działanie ognia i kwasów.',
    'content': [
      { 'section': '<strong>Komin systemowy</strong> coraz częściej zaczyna konkurować <strong>z tradycyjnym kominem murowanym</strong>. Składa się on z fabrycznie przygotowanych, dopasowanych zestawów rur, kształtek jak i wszelkiego rodzaju akcesoriów pomocniczych.  Wszystkie wykonane są z materiałów odpornych na działanie ognia i kwasów.' },
      { 'section': '<strong>Komin systemowy</strong> jest bardzo prosty w montażu. Jego <strong>rdzeniem jest ceramiczna rura obudowana pustakami</strong>. Czasami między warstwą zewnętrzną a wewnętrzną zostaje dodatkowo umieszczona <strong>izolacja termiczna</strong>, która <strong>chroni przed nadmierną utratą ciepła</strong>.' },
      { 'section': '<strong>Komin systemowy</strong> można zamontować samodzielnie, przez co zmniejszamy koszty montażu. Dodatkowym atutem jest jego rozmiar, zajmuje on dużo mniej miejsca, niż <strong>tradycyjny murowany komin</strong>. Jest to bardzo istotne w momencie, kiedy posiadamy mały metraż domu. Warte zaznaczenie jest to, iż komin systemowy możemy montować również na zewnątrz budynku.' },
      { 'section': 'Dużą <strong>zaletą kominów systemowych</strong> jest (w większości)  możliwość zmiany źródła ogrzewania bez konieczności dokonywania zmian w konstrukcji przewodów kominowych.' },
      { 'section': '<strong>nstalacja wykonana z prefabrykatów</strong> idealnie synchronizuje się z wieloma systemami grzewczymi. Do takiego komina można podłączyć wkład z systemem dystrybucji gorącego powietrza. W przypadku ogrzewania kotłem kondensacyjnym warto rozejrzeć się za systemem kominowym ze specjalnymi oddzielnymi kanałami powietrznym, odprowadzającymi parę wodną.' },
      { 'section': 'Na rynku możemy spotkać <strong>kominy systemowe szamotowe, stalowe i mieszane</strong>.' },
      { 'section': '' }
    ],
    'slug': 'komin-systemowy-wady-i-zalety',
    'outerlink': 'https://wbudowie.pl/komin-systemowy-wady-i-zalety-prawda-o-systemach-kominowych'
  },
  {
    'id': 9,
    'title': 'Kontrola przewodów kominowych.',
    'meta': 'Kontrole przewodów kominowych są bardzo ważne z wielu względów i bardzo istotne jest, aby wykonywane były przez profesjonalistę.',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/kontrola-przewodow-kominowych-360x189.jpeg',
        'alt': 'kontrola przewodów kominowych',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/kontrola-przewodow-kominowych-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/kontrola-przewodow-kominowych.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': '<strong>Kontrole przewodów kominowych</strong> są bardzo ważne z wielu względów i bardzo istotne jest, aby wykonywane były przez profesjonalistę. Mają one służyć ocenie stanu komina, jak i jego okresowemu czyszczeniu. Mamy wtedy pewność, że nasz komin jest zadbany i nie stanowi zagrożenia dla zdrowia i życia mieszkańców domu.',
    'content': [
      { 'section': '<strong>Kontrole przewodów kominowych</strong> są bardzo ważne z wielu względów i bardzo istotne jest, aby wykonywane były przez profesjonalistę. Mają one służyć ocenie stanu komina, jak i jego okresowemu czyszczeniu. Mamy wtedy pewność, że nasz komin jest zadbany i nie stanowi zagrożenia dla zdrowia i życia mieszkańców domu.' },
      { 'section': 'Obowiązek jaki spoczywa na właścicielu domu (przegląd kominiarski) wprowadzono ustawą o prawie budowlanym w 1994r. Prawo to mówi o nakazie wykonania takiego przeglądu raz w roku, jeżeli budynek przekracza powierzchnię zabudowy 2000m2 –dwa razy do roku, do 31 maja i do 31 listopada. Sam przegląd nie wystarcza, obowiązkowe jest również czyszczenie i udrażnianie przewodów kominowych.' },
      { 'section': 'Według wytycznych Krajowej Izby Kominiarzy, przepisy przeciwpożarowe zobowiązują właściciela do usunięcia zanieczyszczeń z przewodów dymowych, wentylacyjnych i spalinowych. Częstotliwość tych czynności to :' },
      { 'section': '- Dla przewodów od palenisk opalanych paliwem stałym – 3 miesiące' },
      { 'section': '- Dla przewodów do palenisk opalanych paliwem gazowym i płynnym – 6 miesięcy' },
      { 'section': '- Dla przewodów wentylacyjnych – 1 raz w roku' },
      { 'section': 'Dla bezpieczeństwa swojego, jak i swojej rodziny należy wykonywać okresowe kontrole przewodów kominowych. Biorąc pod uwagę zdrowie najbliższych, ceny za usługi kominiarskie nie wydają się tak wielkie. Średnia cena za czyszczenie przewodów kominowych i ich <strong>kontrola w domku jednorodzinnym to przedział 112,00-130,00 zł</strong>. (zależy od rejonu Polski ), natomiast ceny za kompleksowe usługi kominiarskie, czyli sprawdzenie stanu technicznego, czyszczenie przewodów kominowych, sprawdzenie szczelności instalacji gazowej to przedział 230,00 do 270,00 zł.' }
    ],
    'slug': 'kontrola-przewodów-kominowych',
    'outerlink': 'https://wbudowie.pl/kontrola-przewodow-kominowych'
  },
  {
    'id': 10,
    'title': 'Komin wentylacyjny.',
    'meta': 'Komin wentylacyjny to pionowa konstrukcja, która jest odpowiedzialna za wypuszczenie zużytego powietrza na zewnątrz budynku.',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/komin-wentylacyjny-360x189.jpeg',
        'alt': 'komin-wentylacyjny',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/komin-wentylacyjny-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/komin-wentylacyjny.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': '<strong>Komin wentylacyjny</strong> to pionowa konstrukcja, która jest odpowiedzialna za wypuszczenie zużytego powietrza na zewnątrz budynku. Umożliwia tym samy wentylację grawitacyjną. Aby komin wentylacyjny poprawnie funkcjonował, musi być odpowiednio zaprojektowany i zbudowany.',
    'content': [
      { 'section': '<strong>Komin wentylacyjny</strong> to pionowa konstrukcja, która jest odpowiedzialna za wypuszczenie zużytego powietrza na zewnątrz budynku. Umożliwia tym samy wentylację grawitacyjną. Aby <strong>komin wentylacyjny</strong> poprawnie funkcjonował, musi być odpowiednio zaprojektowany i zbudowany.' },
      { 'section': 'Liczba jak i rozmieszczenie oraz miejsce kanałów wentylacyjnych ustalana jest przez projektanta budynku. Wszystkie zmiany będą wymagały profesjonalnej adaptacji projektu.' },
      { 'section': 'Kominy wentylacyjne dzielimy na nawiewne, które dostarczają powietrze konieczne do procesu spalania i wyciągowe, które służą do wymiany zużytego powietrza w poszczególnych pomieszczeniach.' },
      { 'section': '<strong>Średnica komina wentylacyjnego</strong> nie powinna być mniejsza niż 150mm. Zalecane wymiary to 14x14cm i 14x20cm. Wytrzymałość pustaków nie powinna być mniejsza niż 5MPa, natomiast ich nasiąkliwość powinna mieścić się w granicach od 6 do 12%.' },
      { 'section': 'Każde pomieszczenie w domu powinno być odpowiednio wentylowane, dlatego strumień powietrza w kominie powinien być dostosowany do rodzaju pomieszczenia i warunków w nim panujących. ' },
      { 'section': '- Kuchnia z oknem, w której zainstalowana jest kuchenka gazowa czy węglowa, strumień powietrza powinien wynosić nie mniej niż 70m3/h' },
      { 'section': '- W kuchni z oknem z kuchenką elektryczną, w mieszkaniu dla 3 osób – minimum 30m3/h, powyżej 3 osób – 50m3/h' },
      { 'section': '- W kuchni bez okna z kuchenką gazową lub węglową – wskazana wentylacja wywiewno-nawiewna –minimum 70m3/h' },
      { 'section': '- W kuchni bez okna z kuchenka elektryczną – minimum 50m3/h' },
      { 'section': '- W WC – minimum 30m3/h' },
      { 'section': '- W łazience – nie mniejszy niż 50m3/h' },
      { 'section': '- Garderoba – 15m3/h' }
    ],
    'slug': 'komin-wentylacyjny',
    'outerlink': 'https://wbudowie.pl/komin-wentylacyjny'
  },
  {
    'id': 11,
    'title': 'Zły ciąg w kominie. Czym jest spowodowany i jak mu zapobiec.',
    'meta': 'Aby nasz piec pracował sprawnie, ciąg w kominie nie może być ani za słaby, ani zbyt mocny. Niestety przy zbyt słabym ciągu, rozpalenie ognia może okazać się trudne.',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/zly-ciag-w-kominie-czym-jest-spowodowany-i-jak-mu-zapobiegac-360x189.jpeg',
        'alt': 'zly-ciag-w-kominie-czym-jest-spowodowany-i-jak-mu-zapobiegac',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/zly-ciag-w-kominie-czym-jest-spowodowany-i-jak-mu-zapobiegac-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/zly-ciag-w-kominie-czym-jest-spowodowany-i-jak-mu-zapobiegac.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': 'Aby nasz piec pracował sprawnie, ciąg w kominie nie może być ani za słaby, ani zbyt mocny. Niestety przy zbyt słabym ciągu, rozpalenie ognia może okazać się trudne. Nawet jeżeli nam się to uda, musimy liczyć się z tym, że dym może się cofnąć na pomieszczenia, natomiast paliwo nie spali się całkowicie. Bardzo ważna rzecz, o której musimy pamiętać jest spalanie paliwa. Niewłaściwe spalanie może powodować powstawanie zabójczego tlenku węgla, którego ani nie zobaczymy, ani nie poczujemy.',
    'content': [
      { 'section': 'Aby nasz piec pracował sprawnie, ciąg w kominie nie może być ani za słaby, ani zbyt mocny. Niestety przy zbyt słabym ciągu, rozpalenie ognia może okazać się trudne. Nawet jeżeli nam się to uda, musimy liczyć się z tym, że dym może się cofnąć na pomieszczenia, natomiast paliwo nie spali się całkowicie. Bardzo ważna rzecz, o której musimy pamiętać jest spalanie paliwa. Niewłaściwe spalanie może powodować powstawanie zabójczego tlenku węgla, którego ani nie zobaczymy, ani nie poczujemy.' },
      { 'section': 'Gdy ciąg w kominie będzie zbyt duży, zbyt duże będzie również spalanie paliwa tym samym piec czy kominek w pełni nie wykorzystają swojej moc. W praktyce może to oznaczać, że w pomieszczeniach mimo ogrzewania wciąż może panować chłód.' },
      { 'section': 'Co wpływa na złą pracę komina :' },
      { 'section': '- Wiatr, który może powodować ciąg wsteczny. Wiatr opadający może powodować powstawanie stref ciśnienia przez zawirowania w okolicy komina i mogą powstawać strefy ciśnienia. Efektem tego może być cofanie dymu do pomieszczeń, powstanie tzw. Ciągu wstecznego.' },
      { 'section': '- Temperatura komina, która kiedy jest zbyt niska, ciąg nie będzie optymalny.' },
      { 'section': '- Zbyt niski komin – minimalna wysokość to około 5m dla domu jednorodzinnego. Przy odpowiedniej wysokości możliwe jest bezpieczne odprowadzanie spalin poza budynek.' },
      { 'section': '- Niewłaściwy przekrój i budowa komina powodują ciągłe problemy z ciągiem. Średnica przewodu powinna być odpowiednio dopasowana do wielkości paleniska i tak w palenisko o przekroju mniejszym niż 25 cm, średnica to 15 cm.' },
      { 'section': '- Zły stan techniczny komina- każda nierówność od wewnętrznej strony powoduje osadzanie się sadzy, co za tym idzie, pogarsza się ciąg w kominie.' },
      { 'section': 'Najważniejsze to ustalić przyczynę złego ciągu, wtedy będzie wiedzieli od czego zacząć i ile czasu potrwają prace. Jeżeli będzie spowodowany zbyt dużą ilością sadzy, wtedy musimy poprosić o interwencje kominiarza. Kiedy okaże się, że komin jest w złym stanie technicznym, sprawa już nie będzie tak prosta.' }
    ],
    'slug': 'zly-ciag-w-kominie-czym-jest-spowodowany-i-jak-mu-zapobiegac',
    'outerlink': 'https://wbudowie.pl/zly-ciag-w-kominie-czym-jest-spowodowany-i-jak-mu-zapobiegac'
  },
  {
    'id': 12,
    'title': 'Jak cegła klinkierowa na komin, pomagamy w wyborze.',
    'meta': 'Komin z cegły klinkierowej musi być zbudowany bardzo starannie. Ten fragment komina, który znajduje się ponad połacią dachową i jest obudowany cegłą klinkierową wygląda niepowtarzalnie i bardzo estetycznie.',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/jaka-cegla-klinkierowa-na-komin-pomagamy-w-wyborze-360x189.jpeg',
        'alt': 'jaka-cegla-klinkierowa-na-komin-pomagamy-w-wyborze',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/jaka-cegla-klinkierowa-na-komin-pomagamy-w-wyborze-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/jaka-cegla-klinkierowa-na-komin-pomagamy-w-wyborze.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': 'Komin z cegły klinkierowej musi być zbudowany bardzo starannie. Ten fragment komina, który znajduje się ponad połacią dachową i jest obudowany cegłą klinkierową wygląda niepowtarzalnie i bardzo estetycznie. Jednak <strong>cegła klinkierowa na komin</strong> to nie tylko ładny wygląd, ale również trwałość.',
    'content': [
      { 'section': 'Komin z cegły klinkierowej musi być zbudowany bardzo starannie. Ten fragment komina, który znajduje się ponad połacią dachową i jest obudowany cegłą klinkierową wygląda niepowtarzalnie i bardzo estetycznie. Jednak <strong>cegła klinkierowa na komin<> to nie tylko ładny wygląd, ale również trwałość.' },
      { 'section/b': 'Najczęściej wybierane cegły na komin to cegła pełna lub dziurawka. Cegła na komin pełna musi charakteryzować się gładką powierzchnią boczną. Zawsze możemy sięgnąć po cegłę klinkierową, musimy jednak pamiętać, że jest to rozwiązanie dość kosztowne.' },
      { 'section': '<strong>Cegła klinkierowa na komin</strong> jest naturalnym produktem, który z czasem nie zmienia swoich parametrów technicznych i cech kolorystycznych. Jest to bardzo ważne, ponieważ w tym przypadku zbędna jest impregnacja komina. Oczywiście można ją wykonać (nawet jest zalecana) w przypadku cegieł o większej nasiąkliwości, wyłącznie sprawdzonymi preparatami. Taką impregnację powinniśmy przeprowadzić po 7 dniach od zakończonych prac murarskich.' },
      { 'section': 'Wykorzystanie cegły dziurawki do wymurowania komina nie będzie zbyt rozsądnym pomysłem. Woda bez problemu wnika w otwory w cegle, w efekcie przy dużej liczbie złej jakości spoin, woda może dotrzeć do najniższego poziomu domu, co spowoduje zawilgocenie na powierzchni ściany.' },
      { 'section': 'Przed zakupem większej ilości cegieł, należy kupić kilka sztuk aby je sprawdzić. W takim celu należy wykorzystać młotek murarski. Cegła na komin pod wpływem uderzenia powinna wydać czysty, metaliczny odgłos. Odgłos głuchy będzie świadczył o złej jakości cegły.' },
      { 'section': 'Następny sposób to swobodne spuszczenie cegły z około 2m. Powinna przełamać się na dwie części lub wyszczerbić się na krawędziach. Jeżeli testowana cegła rozpadnie się na kawałki, świadczy to o złej jakości i taką partię trzeba pominąć.' },
      { 'section': 'Dobrej jakości cegła powinna mieć jednolitą barwę, bez plam czy smug.' }
    ],
    'slug': 'jaka-cegla-klinkierowa-na-komin-pomagamy-w-wyborze',
    'outerlink': 'https://wbudowie.pl/jaka-cegla-klinkierowa-na-komin-pomagamy-w-wyborze'
  },
  {
    'id': 13,
    'title': 'Strażak na komin.',
    'meta': 'Strażak na komin to inaczej nasada kominowa, która jest instalowana na szczycie komina i służy do stabilizacji i wspomagania ciągu kominowego.',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/strazak-na-komin-360x189.jpeg',
        'alt': 'strazak-na-komin',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/strazak-na-komin-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/strazak-na-komin.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': 'Strażak na komin to inaczej nasada kominowa, która jest instalowana na szczycie komina i służy do stabilizacji i wspomagania ciągu kominowego. Zabezpiecza również przewód kominowy przed powstaniem ciągu wstecznego. Strażak na komin jest również doskonałym zabezpieczeniem przed ptactwem, które często upatruje sobie kominy do budowy gniazda.',
    'content': [
      { 'section': '<strong>Strażak na komin</strong> to inaczej nasada kominowa, która jest instalowana na szczycie komina i służy do stabilizacji i wspomagania ciągu kominowego. Zabezpiecza również przewód kominowy przed powstaniem ciągu wstecznego. Strażak na komin jest również doskonałym zabezpieczeniem przed ptactwem, które często upatruje sobie kominy do budowy gniazda.' },
      { 'section': 'Rozporządzenie ministra infrastruktury z 12 kwietnia 2002r mówi o tym, iż nasady kominowe muszą być stosowane na przewodach dymowych i spalinowych w budynkach usytuowanych w II i III strefie obciążenia wiatrem.' },
      { 'section': '<strong>Strażak na komin</strong> montuje się wszędzie tam, gdzie występują zaburzenia ciągu, czyli na obszarach, gdzie wieją bardzo silne wiatry, gdy na około posesji rosną wysokie drzewa lub gdy dom otoczony jest wysokimi budynkami, gdy przewód spalinowy lub dymowy jest krótki i ma mały przekrój lub przewód komina znajduje się poniżej kalenicy.' },
      { 'section': 'Strażaki na komin różnią się od siebie działaniem, wydajnością i przeznaczeniem. I tak rozróżniamy nasady:' },
      { 'section': '- <strong>Stałe</strong> – nie zmieniają swojego położenia w stosunku do wiejącego wiatru, mają zastosowanie na zakończeniach przewodów wentylacyjnych i spalinowych gazowych.' },
      { 'section': '- <strong>Samonastawne</strong> – ustawiają się zgodnie z kierunkiem podmuchu wiatru, montowane są zarówno na przewodach dymowych, wentylacyjnych i spalinowych. Wyróżnia je najbardziej fantazyjny kształt ze wszystkich nasad kominowych.' },
      { 'section': '- <strong>Obrotowe</strong> – wiatr wprawia głowice w ruch obrotowy i w przewodzie powstaje podciśnienie, które stabilizuje ciąg. Zapobiega również powstawaniu ciągu wstecznego. Stosuje się je na przewodach wentylacyjnych.' },
      { 'section': '- <strong>Hybrydowe</strong> – nasady takie składają się nasady obrotowej i silnika, który w przypadku braku wiatru wprawia je w ruch.' },
      { 'section': 'Z uwagi na to, iż <strong>strażak na komin</strong> montuje się na wysokościach, prace te powinny być wykonane przez fachowca z odpowiednim uprawnieniem. Nasady kominowe powinny być regularnie czyszczone, ponieważ jest ona częścią komina również podlega przeglądowi kominiarskiemu.' }
    ],
    'slug': 'strazak-na-komin',
    'outerlink': 'https://wbudowie.pl/strazak-na-komin-co-to-jest-i-czym-kierowac-sie-przy-wyborze'
  },
  {
    'id': 14,
    'title': 'Kiedy można zbudować komin zewnętrzny?',
    'meta': 'Komin zewnętrzny jest dobrym rozwiązaniem, gdy stary komin nie może być już używany do odprowadzania spalin  z kominka czy kotła. ',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/kiedy-mozna-zbudowac-komin-zewnetrzny-360x189.jpeg',
        'alt': 'kiedy-mozna-zbudowac-komin-zewnetrzny',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/kiedy-mozna-zbudowac-komin-zewnetrzny-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/kiedy-mozna-zbudowac-komin-zewnetrzny.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': 'Komin zewnętrzny jest dobrym rozwiązaniem, gdy stary komin nie może być już używany do odprowadzania spalin  z kominka czy kotła. Zdarza się tak często w przypadku, gdy zmieniamy lokalizację urządzeń grzewczych, czy też chcemy wybudować nieplanowany wcześniej kominek. Może być również tak, iż będziemy chcieli wyposażyć dom w dodatkowy kocioł na tańsze paliwo. W takich sytuacjach skomplikowane staje się rozbieranie części połaci dachowej i przekuwanie stropu. ',
    'content': [
      { 'section': '<strong>Komin zewnętrzny</strong> jest dobrym rozwiązaniem, gdy stary komin nie może być już używany do odprowadzania spalin  z kominka czy kotła. Zdarza się tak często w przypadku, gdy zmieniamy lokalizację urządzeń grzewczych, czy też chcemy wybudować nieplanowany wcześniej kominek. Może być również tak, iż będziemy chcieli wyposażyć dom w dodatkowy kocioł na tańsze paliwo. W takich sytuacjach skomplikowane staje się rozbieranie części połaci dachowej i przekuwanie stropu. ' },
      { 'section': 'Jednak zanim zdecydujemy się na budowę komina zewnętrznego warto wiedzieć, jakie wymogi trzeba spełnić.' },
      { 'section': '<strong>Komin zewnętrzny</strong> powinien mieć właściwy przekrój jak i odpowiednia wysokość, aby mógł wytworzyć się odpowiedni ciąg. Minimalne wymiary komina zewnętrznego to 14x14cm, lub średnica 15cm, natomiast wysokość to co najmniej 4m. Ułożenie komina zewnętrznego nie powinno odbiegać od pionu i powinien on być bez załamań ani zmian kierunku. Odchylenie nie może przekroczyć 30 stopni na odcinku 2 metrowym.' },
      { 'section': 'Montaż, który sprawi najmniej kłopotu to montaż systemowego komina ze stali nierdzewnej. Na komin zewnętrzny można również wybrać komin ceramiczny lub tradycyjny murowany. Murowane kominy wymagają osobnych fundamentów i solidnego zakotwiczenia w ścianie, natomiast kominy stalowe mocuje do ściany za pomocą specjalnych uchwytów.' },
      { 'section': '<strong>Komin zewnętrzny stalowy</strong> czy też ceramiczny ma bardzo zbliżone zalety użytkowe, stal kwasoodporna, ceramika szamotowa czy kamionka kwasoodporna są tak samo niewrażliwe na agresywne spaliny kotłów.' }
    ],
    'slug': 'kiedy-mozna-zbudowac-komin-zewnetrzny',
    'outerlink': 'https://wbudowie.pl/kiedy-mozna-zbudowac-komin-zewnetrzny'
  },
  {
    'id': 15,
    'title': 'Filtr na komin',
    'meta': 'Filtr na komin składa się z generatora wysokiego napięcia, elektrody ulotowej, korpusu, izolatora ceramicznego, kanału powietrznego, wentylatora i przewodu zasilającego.',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/filtr-na-komin-360x189.jpeg',
        'alt': 'filtr-na-komin',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/filtr-na-komin-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/filtr-na-komin.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': 'Powietrze jakim oddychamy należy do najbardziej zanieczyszczonych w Europie. Stężenie pyłów PM2,5 oraz PM10 wielokrotnie przekracza dopuszczalne normy. Największym zagrożeniem są domowe kotłownie i emitowane z nich bardzo szkodliwe zanieczyszczenia powietrza tzw. Niska emisja. Niestety większość ludzi nadal eksploatuje stare wciąż sprawne urządzenia, które były produkowane bez większego nacisku na emisje spalin do atmosfery. W takiej sytuacji warto pomyśleć, aby do naszego domu zamontować filtr na komin.',
    'content': [
      { 'section': 'Powietrze jakim oddychamy należy do najbardziej zanieczyszczonych w Europie. Stężenie pyłów PM2,5 oraz PM10 wielokrotnie przekracza dopuszczalne normy. Największym zagrożeniem są domowe kotłownie i emitowane z nich bardzo szkodliwe zanieczyszczenia powietrza tzw. Niska emisja. Niestety większość ludzi nadal eksploatuje stare wciąż sprawne urządzenia, które były produkowane bez większego nacisku na emisje spalin do atmosfery. W takiej sytuacji warto pomyśleć, aby do naszego domu zamontować <strong>filtr na komin</strong>.' },
      { 'section': 'Instalacje odpylające - cyklony i multicyklony - są dobrze znane osobom, które ogrzewają domy paliwem stałym. Są one nadal <strong>bardzo skutecznym filtrem</strong>. Zmieniły się delikatnie wytyczne co do produkcji tj. cyklonów nie wykłada się już bazaltem, ponieważ obniża on skuteczność odpylania.' },
      { 'section': 'Bardzo popularne stały się elektrofiltry kominowe, montowane w układzie odprowadzania spalin. Filtr na komin  powinien być montowany w przypadku ogrzewania domu za pomocą kotła z podajnikiem. Znajduje się on między wylotem spalin z kotła, a przewodem kominowym. <strong>Filtr na komin</strong> ma za zadanie przyciąganie do ścianek urządzenia drobinek pyłów. Drobinki te otrzymują ładunki elektryczne, ponieważ na elektrodzie, która znajduje się  w strumieniu spalin, wytwarza się wysokie napięcie.' },
      { 'section': 'Filtr na komin składa się z generatora wysokiego napięcia, elektrody ulotowej, korpusu, izolatora ceramicznego, kanału powietrznego, wentylatora i przewodu zasilającego.' },
      { 'section': 'Niestety <strong>nowoczesny filtr na komin</strong> całkowicie nie wyeliminuje emisji szkodliwych związków, ale w bardzo dużym stopniu go ograniczy. Ogromny wpływ na jego skuteczność działania ma praca instalacji kotłowej, jak również rodzaj pieca.' }
    ],
    'slug': 'filtr-na-komin',
    'outerlink': 'https://wbudowie.pl/filtr-na-komin-ogranicza-emisje-trujacych-gazow'
  },
  {
    'id': 16,
    'title': 'Jaki komin wybrać do kominka.',
    'meta': 'Komin do kominka powinien być przede wszystkim szczelny. Musi być również odporny na wysoką temperaturę, jak i substancje, które są wydzielane w trakcie spalania paliwa. ',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/jaki-komin-wybrac-do-kominka-360x189.jpeg',
        'alt': 'jaki-komin-wybrac-do-kominka',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/jaki-komin-wybrac-do-kominka-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/jaki-komin-wybrac-do-kominka.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': 'Komin do kominka powinien być przede wszystkim szczelny. Musi być również odporny na wysoką temperaturę, jak i substancje, które są wydzielane w trakcie spalania paliwa. ',
    'content': [
      { 'section': '<strong>Komin do kominka</strong> powinien być przede wszystkim szczelny. Musi być również odporny na wysoką temperaturę, jak i substancje, które są wydzielane w trakcie spalania paliwa. ' },
      { 'section': 'Dla naszego bezpieczeństwa komin do kominka powinien posiadać atest dla pieców na paliwo stałe. Średnica takiego komina powinna odpowiadać średnicy wylotu spalin wkładu kominkowego. Minimalna średnica to 15cm., natomiast minimalny wymiar w przypadku prostokątnego przewodu to 14x14cm. Minimalna wysokość komina do kominka to 4m liczone od paleniska, natomiast kanał dymowy powinien wytwarzać ciąg w przedziale 10-30 Pa i powinien być w bezpiecznej odległości od łatwopalnych elementów.' },
      { 'section': 'Kominy jakie możemy wybrać do kominka to komin murowany i ceramiczny lub stalowy. ' },
      { 'section': 'Kominy murowane z cegły pełnej mają dużą zaletę, mianowicie, posiadają dużą pojemność cieplną co oznacza w praktyce wolne stygnięcie takiego komina. Można również <strong>delikatnie odchylić komin od pionu</strong>, aby bezpiecznie ominąć przeszkodę. Minusem natomiast jest mała odporność na wilgoć i agresywne kwasy. Komin murowany powinien być uszczelniony wkładką z rur żaroodpornych i kwasoodpornych.' },
      { 'section': 'Kominy ceramiczne są bardzo popularne, składają się z rury ceramicznej, izolacji , pustaka z lekkiego betonu. Dzięki temu, iż wszystko jest z prefabrykowanych elementów, montaż jest bardzo uproszczony. Komin ceramiczny jest bardzo odporny na wilgoć i agresywne kwasy, jak i temperaturę dochodzącą nawet do 600 ͦC. Gładka powierzchnia powoduje wolniejsze osadzanie się sadzy, co znacznie ułatwia czyszczenie komina.' },
      { 'section': '<strong>Kominy do kominka</strong> stalowe wykonane są z zewnątrz ze stali szlachetnej natomiast wnętrze to żaroodporne rury stalowe. Pozostałe wnętrze wypełnia specjalna izolacja. Zaletą tych kominów to przede wszystkim lekka konstrukcja , która nie wymaga fundamentu, jak również możliwość montażu bezpośrednio nad kominkiem i na zewnątrz budynku bez dodatkowej izolacji. Warto zaznaczyć, że komin stalowy do kominka zajmuje najmniej miejsca.' }
    ],
    'slug': 'jaki-komin-wybrac-do-kominka',
    'outerlink': 'https://wbudowie.pl/jaki-komin-wybrac-do-kominka'
  },
  {
    'id': 17,
    'title': 'Jaki komin do pieca gazowego?',
    'meta': 'Przy wyborze komina do pieca gazowego musimy pamiętać, aby był on kwasoodporny, dlatego w przypadku kominów tradycyjnych murowanych , konieczne jest zastosowanie wkładu, który ma właśnie takie właściwości.',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/jaki-komin-do-pieca-gazowego-360x189.jpeg',
        'alt': 'jaki-komin-do-pieca-gazowego',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/jaki-komin-do-pieca-gazowego-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/jaki-komin-do-pieca-gazowego.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': 'Przy wyborze komina do pieca gazowego musimy pamiętać, aby był on kwasoodporny, dlatego w przypadku kominów tradycyjnych murowanych , konieczne jest zastosowanie wkładu, który ma właśnie takie właściwości.',
    'content': [
      { 'section': 'Przy wyborze <strong>komina do pieca gazowego</strong> musimy pamiętać, aby był on kwasoodporny, dlatego w przypadku kominów tradycyjnych murowanych , konieczne jest zastosowanie wkładu, który ma właśnie takie właściwości.' },
      { 'section': 'Komin do pieca gazowego powinien być dopasowany do parametrów urządzenia grzewczego. Musimy zwrócić również uwagę na to, jaką mamy komorę. Jeżeli jest to komora otwarta zalecany jest komin wysoki, w którym będzie mógł być wytwarzany odpowiednio silny ciąg. Minimalna wysokość komina powinna wynosić 4m. W przypadku pieców z komorą zamkniętą i kotłach kondensacyjnych, już w samym urządzeniu zamontowany jest wentylator ułatwiający uzyskanie odpowiedniego ciągu. W takiej sytuacji można zastosować komin o mniejszych rozmiarach, oczywiście po uprzedniej konsultacji z kominiarzem.' },
      { 'section': 'W <strong>kominie do pieca gazowego</strong> odprowadzanie spalin i pobór powietrza może odbywać się na różne sposoby:' },
      { 'section': '- W systemie poziomym' },
      { 'section': '- W systemie pionowo współśrodkowym' },
      { 'section': '- Szlachtowym' },
      { 'section': '- Rozdzielczym' },
      { 'section': 'Na rynku dostępne są gotowe systemy kominowe, używane do ogrzewania domu gazem. Wytwarzane są najczęściej z aluminium lub ceramiki kwasoodpornej, z wyborem nie będziemy mieć kłopotu, bo jest dość spory, ale cena niestety wysoka.' },
      { 'section': 'Właściwie dobrany komin do pieca gazowego daje nam poczucie bezpieczeństwa jak i optymalizacje kosztów za ogrzewanie. Duże znaczeni ma w tej kwestii odpowiedni ciąg w kominie, który pozwala w odpowiednim tempie spalić paliwo.' }
    ],
    'slug': 'jaki-komin-do-pieca-gazowego',
    'outerlink': 'https://wbudowie.pl/jaki-komin-do-pieca-gazowego'
  },
  {
    'id': 18,
    'title': 'Wkład kominowy',
    'meta': 'Wkład kominowy odpowiedzialny jest za szczelność przewodu odprowadzającego spaliny, ale nie tylko. Powala wzmocnić komin i wydłużyć jego żywotność.',
    'image': [
      {
        'src': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/wklad-kominowy-360x189.jpeg',
        'alt': 'wklad-kominowy',
        'source': 'https://wiedza.wbudowie.pl/images/thumb/wbudowie/wklad-kominowy-260x136.jpeg',
        'original': 'https://wiedza.wbudowie.pl/images/wbudowie/wklad-kominowy.jpeg'
      }
    ],
    'video': [
      {
        'show': 'false',
        'link': ''
      }
    ],
    'lead': 'Wkład kominowy odpowiedzialny jest za szczelność przewodu odprowadzającego spaliny, ale nie tylko. Powala wzmocnić komin i wydłużyć jego żywotność.',
    'content': [
      { 'section': '<strong>Wkład kominowy</strong> odpowiedzialny jest za szczelność przewodu odprowadzającego spaliny, ale nie tylko. Powala wzmocnić komin i wydłużyć jego żywotność.' },
      { 'section': 'Wkład kominowy to po prostu rura, którą należy umieścić w kominie po to, aby uzyskać bardzo szczelny przewód. Jest to bardzo ważne ze względu na bezpieczeństwo domowników, gdyż nieszczelny komin wiąże się z zatruciem czadem, może również być przyczyna pożaru.' },
      { 'section': '<strong>Wkład kominowy jest idealnym rozwiązaniem</strong> w sytuacji, kiedy użytkowany komin jest w złym stanie i trzeba go uszczelnić. Oszczędzamy w ten sposób i czas i pieniądze.' },
      { 'section': 'Zadaniem wkładu kominowego jest odprowadzenie z instalacji dymów, pyłów czy sadzy. Bardzo istotną cechą jaką musi spełnić wkład kominowy jest wspomniana wcześniej szczelność. Równie istotna cecha to odporność na zamakanie i odpowiedni system odprowadzania skroplin. Każdy wkład kominowy musi cechować się odpornością na wysokie temperatury jak również dać możliwość odpowiedniego przeprowadzenia przewodów.' },
      { 'section': '<strong>Wkład kominowy powinien być dobrany do indywidualnych potrzeb użytkownika i warunków w jakich pracuje komin</strong>. Inne wkłady stosuje się przy kotłach olejowy inne przy spalaniu drewnem a jeszcze inne będą potrzebne w bardzo starych domach. Dostępne wkłady kominowe możemy podzielić na :' },
      { 'section': '- Wkład kominowy kwasoodporny – nadaje się do montażu przy kotłach olejowy i gazowych, zabezpiecza komin przed kwaśnymi skroplinami.' },
      { 'section': '- Wkład kominowy żaroodporny – nadaje się do montażu przy kotłach zasilanych materiałem drewnopochodnym. Wkład taki nie dopuszcza do przegrzania się komina i jest odporny na bardzo wysokie temperatury.' },
      { 'section': '- Wkład kominowy elastyczny – idealny do renowacji starych kominów, tam gdzie występuje sporo krzywizn.' },
      { 'section': 'Trwałość komina zależy nie tylko od tego z czego jest zbudowany i jak został zrobiony. Musimy pamiętać również o jego systematycznym czyszczeniu i regularnych przeglądach.' }
    ],
    'slug': 'wklad-kominowy',
    'outerlink': 'https://wbudowie.pl/wklady-kominowe-ich-rodzaje-i-funkcje'
  }
]

export default {
  getArticles (cb) {
    setTimeout(() => cb(_articles), 100)
  }
}
