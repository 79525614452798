const _analitycs = [
  {
    'active': true,
    'type' : 'gtm',
    'id': 'GTM-MWQCRBQ'
  },
  {
    'active': true,
    'type' : 'gtm',
    'id': 'GTM-KXD8Z8D'
  }
]

export default {
  getAnalitycs (cb) {
    setTimeout(() => cb(_analitycs), 100)
  }
}
