const _footer = [
  {
    'copyright': [
      {
        'id': '1',
        'content': 'Copyright 2018 wbudowie.pl - Pomagamy budować. Wszystkie prawa zastrzeżone.',
        'size': '8'
      },
      {
        'id': '2',
        'content': '<a href="tel:+483435353">Infolinia: +48 (41) 313 53 53</a>',
        'size': '4'
      },
      {
        'id': '3',
        'content': null
      },
      {
        'id': '4',
        'content': null
      }
    ],
    'class': 'wbudowie',
    'style': '1'
  }
]

export default {
  getFooter (cb) {
    setTimeout(() => cb(_footer), 100)
  }
}
