const _global = [
  {
    'url': 'wiedza.wbudowie.pl',
    'image': 'https://wbudowie.pl/media/cache/resolve/optimize/files/609192_wbudowie.png',
    'class': 'wbudowie',
    'style': '1',
    'title': 'Najnowasze artykuły budowlane z naszych serwisów.',
    'meta': 'Zapoznaj się z pełną listą artykułów z tematyki budowlanej. Duża porcja informacji oraz poradników budowlanych.',
    'keywords': 'poradnik budowlany',
    'favicon': 'data:image/x-icon;base64,AAABAAEAEBAAAAEAIAArAQAAFgAAAIlQTkcNChoKAAAADUlIRFIAAAAQAAAAEAgGAAAAH/P/YQAAAAlwSFlzAAALEwAACxMBAJqcGAAAAN1JREFUOI3F0S1OQ0EUxfHftCyAkNSQoEFicKg69DOEBAWGJeBw+KoKUCS4sSiCYgEV7IAESdhAcxGMeMybkqJ6kivmzLn/Ox8pIqSU1IpsB/dleZE6n4NMhNQCRHaMR+wV6x1nqfP6JyCyMa5xg3E1cFn829RZDgCR7eIB08F9fusF56nzERFGZfIJFo3mt1J9TbEoPT8APGFSBec4KjWv9ialx1bjiF+4TJ3c864ie8YdtvvhkaFmVTMo3qz2W4BoeCv3WoB/afOA1i+cRna4In+wDmC/1Fra/Bt8A+YURJ20eJ6RAAAAAElFTkSuQmCC',
    'cookie': {
      'message': 'Korzystając z portalu wiedza.wbudowie.pl bez zmiany ustawień przeglądarki, zgadzasz się na użycie plików cookies i podobnych technologii w celach: świadczenia usług, analiz, statystyk, reklamy. Przeglądając serwis bez zmiany ustawień wyrażasz zgodę na użycie plików cookies. Więcej w Polityce Prywatności i Cookies. Pamiętaj, że zawsze możesz zmienić te ustawienia.',
      'link': 'regulamin',
      'text': 'Przeczytaj',
      'btn': 'Rozumiem'
    }
  }
]

export default {
  getGlobal (cb) {
    setTimeout(() => cb(_global), 100)
  }
}
