/*tslint no-unused-expressions: "error"*/
import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
Vue.use(VueRouter)
Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid'
})
import 'es6-promise/auto'
import store from './store/store'
import appVue from './App.vue'

import getRoutes from './api/wbudowie/routes'
import getAnalitycs from './api/wbudowie/analitycs'

// import getRoutes from './api/blogmaesto/routes'
// import getAnalitycs from './api/blogmaesto/analitycs'

// import getRoutes from './api/muchymeskie/routes'
// import getAnalitycs from './api/muchymeskie/analitycs'

// import getRoutes from './api/spinkimeskie/routes'
// import getAnalitycs from './api/spinkimeskie/analitycs'

interface Analitycs {
  active: boolean,
  type: string,
  id: string
}

const getAnalitycsPromise = new Promise(function (resolve, reject) {
  getAnalitycs.getAnalitycs((el: Array<any>) => {
    resolve(el)
  })
})

getAnalitycsPromise.then((result: Array<any>) => {
  // let headScript = document.querySelector('#head-custom-analitycs')
  // let bodyScript = document.querySelector('#body-custom-analitycs')
  let headHtml = document.querySelector('head')
  let bodyHtml = document.querySelector('body')
  // console.log(headScript)
  // console.log(bodyScript)
  // console.log(headHtml)

  result.forEach((element: Analitycs, index) => {
    console.log(element)
    if (element.active === true && element.type === 'gtm') {
      let id = ''
      id = element.id

      const code = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${id}');`
      const iframe = `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`

      let scriptTag = document.createElement('script')
      scriptTag.id = 'gtm-head-' + index
      scriptTag.innerHTML = code

      let noscriptTag = document.createElement('noscript')
      noscriptTag.id = 'gtm-body-' + index
      noscriptTag.innerHTML = iframe

      const checkGtmHead = document.querySelector('#gtm-head-' + index)
      const checkGtmBody = document.querySelector('#gtm-body-' + index)

      if (!checkGtmHead) {
        console.log(checkGtmHead)
        headHtml.prepend(scriptTag)
      }
      if (!checkGtmBody) {
        console.log(checkGtmBody)
        bodyHtml.prepend(noscriptTag)
      }

    } else if (element.active === true && element.type === 'ga') {
      let id = ''
      id = element.id

      let scriptTag = document.createElement('script')
      scriptTag.id = 'ga-' + index
      scriptTag.async = true
      scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${id}`

      let scriptCode = document.createElement('script')
      scriptCode.text = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${id}');`
      const checkGa = document.querySelector('#ga-' + index)
      if (!checkGa) {
        headHtml.prepend(scriptTag)
        headHtml.prepend(scriptCode)
      }
    } else {
      return false
    }
  })
})

class App {
  constructor () {
    const app = this
  }

  run () {
    const getRoutesPromise = new Promise(function (resolve, reject) {
      getRoutes.getRoutes((el: Array<any>) => {
        resolve(el)
      }, (s: String) => {
        resolve(s)
      })
    })

    getRoutesPromise.then((result: Array<any>) => {
      console.log(result)

      const router = new VueRouter({
        mode: 'history',
        routes: result
      })

      const vueApp = new Vue({
        el: '#app',
        store,
        router,
        render: h => h(appVue)
      }).$mount('#app')

    })
  }
}

const app = new App()
document.addEventListener('DOMContentLoaded', function () {
  app.run()
})
